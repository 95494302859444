<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="8" type="flex" justify="end">
				<a-col :span="6" style="display: flex">
					<div
						style="flex: 1;text-align: right;padding-right: 3px;display:flex;align-items: center;height: 30px;justify-content: flex-end;"
					>
						离职日期:
					</div>
					<div style="flex: 3">
						<a-range-picker @change="dateChange" style="width: 100%" />
					</div>
				</a-col>
				<a-col :span="3">
					<a-tree-select
						class="left"
						@change="handleChange"
						allowClear
						:placeholder="l('Company')"
						style="width: 100%"
						tree-default-expand-all
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="comPanyId"
					/>
				</a-col>
				<a-col :span="3">
					<a-tree-select
						v-model="departmentId"
						allowClear
						style="width: 100%"
						show-search
						:filterTreeNode="searchTree"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						tree-default-expand-all
						@select="SelectDepartmentTree"
						@change="filterData"
					>
					</a-tree-select>
				</a-col>
				<a-col :span="3">
					<a-select
						show-search
						allowClear
						:placeholder="l('员工状态')"
						option-filter-prop="children"
						style="width: 100%"
						v-model="EmpStateCode"
						@change="filterData"
					>
						<a-select-option v-for="item in EmpStateList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="8" offset="1">
					<a-form-item>
						<a-input-search
							style="width: 200px;"
							name="filterText"
							:placeholder="l('SearchWithThreeDot')"
							@search="filterData"
							enterButton
							v-model="filterText"
							v-decorator="['filterText']"
						/>
					</a-form-item>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				@change="sorterChange"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<p class="subscriptionEndUtc" slot="subscriptionEndUtc" slot-scope="record">{{ record }}</p>
				<a class="isActive" slot="isActive" slot-scope="record">
					<a-icon v-if="record" type="check" />
					<a-icon v-if="!record" type="close" />
				</a>
				<span slot="actions" slot-scope="text, record">
					<a
						class="table-edit"
						v-if="isGranted('handle_employee_quit')"
						@click="handleEmployeeQuit(record.id)"
					>
						{{ l('办理离职') }}</a
					>
					<a-divider
						type="vertical"
						v-if="record.empStateCode === '02' && isGranted('handle_employee_withdrawQuit')"
					/>
					<!-- 删除 -->
					<a-popconfirm
						placement="top"
						:okText="l('Ok')"
						:cancelText="l('Cancel')"
						@confirm="withdrawQuit(record)"
						v-if="record.empStateCode === '02' && isGranted('handle_employee_withdrawQuit')"
					>
						<template slot="title">
							{{ '你确定要将' + record.realName + '恢复为在职状态吗？' }}
						</template>
						<a>离职恢复</a>
					</a-popconfirm>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import { Dic } from '../../../shared/utils';
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	EmployeeQuitServiceProxy,
	EmployeeServiceProxy,
} from '../../../shared/service-proxies';
import moment from 'moment';
import ModalHelper from '../../../shared/helpers/modal/modal-helper';
import HandleEmployeeQuit from './handle-employee-quit/handle-employee-quit';
import { abpService } from '../../../shared/abp';

export default {
	name: 'employee-quit',
	mixins: [AppComponentBase],
	components: { HandleEmployeeQuit },
	data() {
		return {
			EmpStateList: [],
			EmpStateCode: undefined,

			// 表格
			columns: [
				{
					title: this.l('工号'),
					dataIndex: 'jobNumber',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: this.l('姓名'),
					dataIndex: 'realName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: this.l('部门'),
					dataIndex: 'depertment',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'depertment' },
				},
				{
					title: this.l('员工类型'),
					dataIndex: 'empType',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'empType' },
				},
				{
					title: this.l('员工状态'),
					dataIndex: 'empState',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'empState' },
				},
				{
					title: this.l('入职日期'),
					dataIndex: 'hireDate',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'hireDate' },
				},
				{
					title: this.l('转正日期'),
					dataIndex: 'regularDate',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'regularDate' },
				},
				{
					title: this.l('离职日期'),
					dataIndex: 'termDate',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'termDate' },
				},
				{
					title: this.l('Actions'),
					dataIndex: 'actions',
					align: 'center',
					scopedSlots: { customRender: 'actions' },
					width: '200px',
				},
			],
			tableData: [],
			companyTreeList: [],
			comPanyId: undefined,
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],
			startTime: undefined,
			endTime: undefined,
		};
	},
	created() {
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._employeeQuitServiceProxy = new EmployeeQuitServiceProxy(this.$apiUrl, this.$api);
		this._employeeServiceProxy = new EmployeeServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.EmpStateList = await Dic.getInstance().getDicAsync('EmpState');
		this.companyTreeInit();
		this.getData();
		if (abpService.abp.userInfo.companyId) {
			this.comPanyId = abpService.abp.userInfo.companyId;
			this.handleChange(this.comPanyId);
		}
	},
	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},

		//离职范围日期框选择
		dateChange(date) {
			if (date.length > 0) {
				this.startTime = date[0];
				this.endTime = date[1];
			} else {
				this.startTime = undefined;
				this.endTime = undefined;
			}
			this.refresh();
		},
		getData() {
			this.loading = true;
			this._employeeQuitServiceProxy
				.getPage(
					this.startTime,
					this.endTime,
					this.comPanyId,
					this.departmentId,
					this.EmpStateCode,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : ' ';
						item.regularDate = item.regularDate ? moment(item.regularDate).format('YYYY-MM-DD') : ' ';
						item.termDate = item.termDate ? moment(item.termDate).format('YYYY-MM-DD') : ' ';
					});
					this.totalItems = res.totalCount;
				});
		},

		/**
		 * 排序
		 * @param pagination
		 * @param filters
		 * @param sorter
		 */
		sorterChange(pagination, filters, sorter) {
			if (sorter.order) {
				this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
			} else {
				this.request.sorting = undefined;
			}
			this.filterData();
		},
		filterData() {
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
		},
		companyTreeInit() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.handleChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		handleChange(value) {
			this.comPanyId = value;
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
			this.departmentId = undefined;
			this.getDepTreeData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = res;
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							let obj = {
								key: item.key,
								value: item.key.toString(),
								title: item.title,
							};
							this.DepTreeData.push(obj);
						}
					});
					this.initDepTree(this.DepTreeData);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initDepTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.DepTree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var obj = {
							key: m.key,
							value: m.key.toString(),
							title: m.title,
						};
						v.children.push(obj); //放进父级的集合中
						this.initDepTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
		},
		handleEmployeeQuit(id) {
			ModalHelper.create(
				HandleEmployeeQuit,
				{
					EmpId: id,
				},
				{
					width: '500px',
				}
			).subscribe((res) => {
				if (res) {
					this.getData();
				}
			});
		},
		withdrawQuit(record) {
			this.loading = true;
			this._employeeServiceProxy
				.withdrawQuit(record.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.getData();
				});
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
